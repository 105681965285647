<template>
  <div id="app">
    <div id="nav">
      <router-link to="/" class="brand">
        <img class="header-icon" src="/img/header-icon.svg" alt="">
        Cristiano Minerals
      </router-link>

      <!-- <router-link class="nav-link" to="/misc">Misc</router-link> -->
      <router-link class="nav-link" to="/about">{{ $t("NAVBAR.ABOUT") }}</router-link>
      <!-- <router-link class="nav-link" to="/files">{{ $t("NAVBAR.FILES") }}</router-link> -->
      <router-link class="nav-link" v-if="isAuthenticated" v-on:click.native="logout" to="/">Logout</router-link>
      <!-- <router-link class="nav-link" v-else to="/login">Login</router-link> -->
    </div>
    <router-view/>
    <!-- <form class="subs-form" @submit.prevent="subscribe">
      <input placeholder="Name" required type="name" name="subsName" v-model="subscription.name">
      <input placeholder="E-mail" required type="email" name="subsEmail" v-model="subscription.email">
      <button type="submit">Subscribe</button>
    </form> -->
    <footer>
      <small>
        Cristiano Minerals &copy; {{ new Date().getFullYear() }}
      </small>
    </footer>
    <div id="toast" v-if="toastMsg">
      <div id="desc">{{ toastMsg }}</div>
    </div>
  </div>
</template>
<script>
  import { EventBus } from './event-bus'
  import UserService from '@/services/user-service'
  import BlogService from '@/services/blog-service'

  export default {
    data() {
      return {
        isAuthenticated: UserService.isAuthenticated(),
        subscription: {
          name: '',
          email: ''
        },
        toastMsg: ''
      }
    },
    mounted() {
      EventBus.$on('user:logged-in', user => {
        UserService.setUser(user)
        this.isAuthenticated = true
      })

      EventBus.$on('toast', msg => {
        this.toastMsg = msg;
      })
    },
    watch: {
      toastMsg: function (val) {
        const self = this;
        if (val) {
          setTimeout(() => {
            self.toastMsg = '';
          }, 4500)
        }
      }
    },
    methods: {
      logout() {
        UserService.logout()
        this.isAuthenticated = false
      },
      subscribe() {
        BlogService.subscribe(this.subscription).then(() => {
          this.subscription = {name: '', email: ''};
          this.toastMsg = 'Thanks for subscribing!';
        });
      }
    }
  }
</script>
<style lang="scss">

html, body {
  height: 100vh;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}

a {
    //font-weight: bold;
    //color: #42b983;
    color: #50C878;

    /*&.router-link-exact-active {
      color: #42b983;
    }*/
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

#nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;

  a {
    font-weight: bold;
    //color: #2c3e50;
    color: #50C878;
    &.router-link-exact-active {
      //color: #42b983;
      color: #50C878;
    }
    text-decoration: none;
    margin-right: 5px;
    font-size: 1.2em;
  }

  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
}

img {
  max-width: 100%;
}

img.header-icon {
  height: 30px;
}

a.brand {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 1.5em;
  color: #2c3e50 !important;


  img {
    margin-right: 5px;
  }
}

a.nav-link {
  margin-left: auto;
}

footer {
  display: flex;
  margin-top: auto;
  padding: 10px;
  justify-content: center;
}

ul {
  list-style: none;
}

input, textarea {
  margin-bottom: 5px;
  height: 30px;
  max-width: 400px;
  font-size: 1.2em;
}

textarea {
  height: auto;
}

button {
  margin-bottom: 5px;
  height: 40px;
  max-width: 150px;
  //background-color: #92f0b3;
  background-color: #50C878;
  outline: none;
  border: 2px solid #fff;
  border-radius: 30px;
  color: #fff;
  font-weight: bolder;
  font-size: 1.2em;
  cursor: pointer;
}

iframe {
  max-width: 100%;
}

.subs-form {
  margin-top: 10px;

  input {
    max-height: 20px;
    margin-right: 5px;
  }
}

#toast {
  max-width: 50px;
  height: 50px;
  /*margin-left: -125px;*/
  margin: auto;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;

  position: fixed;
  z-index: 999;
  left: 0;right:0;
  bottom: 30px;
  font-size: 17px;
  white-space: nowrap;
}

#toast #desc{
  color: #fff;
  padding: 16px;
  overflow: hidden;
  white-space: nowrap;
}

#toast {
  visibility: visible;
  -webkit-animation: fadein 0.5s, expand 0.5s 0.5s,stay 3s 1s, shrink 0.5s 2s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, expand 0.5s 0.5s,stay 3s 1s, shrink 0.5s 4s, fadeout 0.5s 4.5s;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes expand {
  from {min-width: 50px}
  to {min-width: 350px}
}

@keyframes expand {
  from {min-width: 50px}
  to {min-width: 350px}
}
@-webkit-keyframes stay {
  from {min-width: 350px}
  to {min-width: 350px}
}

@keyframes stay {
  from {min-width: 350px}
  to {min-width: 350px}
}
@-webkit-keyframes shrink {
  from {min-width: 350px;}
  to {min-width: 50px;}
}

@keyframes shrink {
  from {min-width: 350px;}
  to {min-width: 50px;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 60px; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 60px; opacity: 0;}
}

</style>
